body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Proxima Nova', 'Open Sans', sans-serif !important;
}

.ui.button {
  font-family: 'Proxima Nova', 'Open Sans', sans-serif !important;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.ui.form .field > label {
  opacity: 0.5;
}

.ui.search.dropdown {
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.ui.vertical.tabular.menu {
  border-right: 0px solid black !important;
}

.ui.vertical.tabular.menu .active.item {
  margin-top: 1px;
  margin-bottom: 1px;
}

.ui.vertical.tabular.menu .active.item {
  border-color: white !important;
  background-color: #f4f4f6 !important;
  border-left: 3px solid #283142 !important;
  color: #283142 !important;
  border-top: 0px solid black !important;
  border-bottom: 0px solid black !important;
  border-right: 0px solid black !important;
  margin-left: 0px;
  padding-left: 17px;
  border-radius: 0px !important;
}

.ui.menu:not(.vertical) .item {
  height: 2.5vh !important;
  margin-bottom: 10px;
  margin-top: 6px;
  margin-left: 10px;
  color: #5f6366 !important;
}

#dashboard .active.item {
  border-color: #283142 !important;
  background-color: #283142 !important;
  color: white !important;
  margin-left: 3px;
  border-radius: 30px !important;
}

.ui.vertical.tabular.menu .active.item i {
  color: #283142 !important;
}

.ui.link.list .item {
  color: #283142 !important;
}

.ui.list > .item a.header {
  color: #31384a !important;
}

.ui.list > .item .header {
  color: #31384a !important;
}

.ui.list > .item .description {
  color: #6b7f99 !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #283142 !important;
  color: #283142 !important;
}

.be-app-element {
  background-color: #f4f4f6;
}

input[type='date']:not(.has-value):before {
  color: lightgray;
  content: attr(placeholder);
}

.be .be-header {
  background: #f4f4f6 !important;
  font-family: 'Proxima Nova', 'Open Sans', sans-serif !important;
}
.be {
  background-color: #f4f4f6;
  font-family: 'Proxima Nova', 'Open Sans', sans-serif !important;
}

[class*='header'] .column {
  height: 90px !important;
}

.drop_area {
  width: 2000px;
  min-height: 200px;
}
